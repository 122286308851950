<template>
    <label :class="classes">
        <slot />
    </label>
</template>

<script>
export default {
    data() {
        return {
            baseClasses: "mb-1 text-sm text-gray-400",
        };
    },
    computed: {
        classes() {
            return `form-label ${this.baseClasses}`;
        },
    },
};
</script>
